<template>
  <v-container fluid class="px-8">
    <v-card flat class="mb-3">
      <v-card-title class="px-0">
        <v-btn class="white--text" small color="blue" @click="createTemplate">
          <v-icon left small>mdi-plus</v-icon>
          {{ $t("add") }}
        </v-btn>
      </v-card-title>
    </v-card>
    <v-card>
      <GsDataTable
        ref="table"
        api="email_manager_api"
        endpoint="/templates"
        :appSetting="appSettingKey"
        :headers="headers"
        :sort-by="['name']"
        :sort-desc="[false]"
        :beforeCallApi="beforeCallApi"
        :afterCallApi="afterCallApi"
      >
        <template v-slot:item.actions="{ item }">
          <GsActionsMenu
            :actions="actions"
            :onActivate="actionId => action_activate(actionId, item)"
          />
        </template>
        <template v-slot:item.name="{ item }">
          <strong class="col-name">{{ item.name }}</strong>
        </template>
        <template v-slot:item.description="{ item }">
          {{ item.description | shortenText }}
        </template>
        <template v-slot:item.locale="{ item }">
          {{ $t(item.locale) }}
        </template>
        <template v-slot:item.createdAt="{ item }">
          <span
            >{{ item.createdAt | getDisplayDate }} ({{
              item.$calculated.$createdBy | empty
            }})</span
          >
        </template>
        <template v-slot:item.updatedAt="{ item }">
          <span
            >{{ item.updatedAt | getDisplayDate }} ({{
              item.$calculated.$updatedBy | empty
            }})</span
          >
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip :color="getStatusColor(item.status)" dark>{{
            $t(item.status.split(".").pop())
          }}</v-chip>
        </template>
        <!-- <template v-slot:item.actions="{ item }">
          <v-icon class="mr-4 my-2" @click="previewTemplate(item)"
            >mdi-eye</v-icon
          >
          <v-icon class="mr-4 my-2" @click="editTemplate(item)"
            >mdi-pencil</v-icon
          >
          <v-icon class="mr-4 my-2" @click="deleteTemplate(item)"
            >mdi-delete</v-icon
          >
          <v-icon class="mr-4 my-2" @click="editAttachedFiles(item)"
            >mdi-file-plus</v-icon
          >
        </template> -->
      </GsDataTable>
    </v-card>
    <!-- create/edit template -->
    <v-dialog v-model="dialogEditorVisible" persistent max-width="600px">
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogEditorVisible = false"
            >{{ $t("close") }}</v-btn
          >
        </v-card-actions>
        <v-card-text>
          <GsTemplateEditor ref="templateEditor" @saved="modalSave" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- attached files -->
    <v-dialog v-model="dialogAttachedFilesVisible" persistent max-width="600px">
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogAttachedFilesVisible = false"
            >{{ $t("close") }}</v-btn
          >
        </v-card-actions>
        <v-card-text>
          <GsTemplateFilesEditor ref="templateFilesEditor" @saved="modalSave" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--template preview -->
    <v-dialog v-model="dialogPreviewVisible" max-width="800px">
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogPreviewVisible = false"
            >{{ $t("close") }}</v-btn
          >
        </v-card-actions>
        <v-card-text>
          <GsTemplatePreview :template="previewTemplateData" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
import GsTemplateEditor from "@/components/GsTemplateEditor";
import GsTemplateFilesEditor from "@/components/GsTemplateFilesEditor";
import GsTemplatePreview from "@/components/GsTemplatePreview";
import { GsDataTable, GsActionsMenu } from "ngt-frontend-core";

export default {
  name: "Templates",
  components: {
    GsTemplateEditor,
    GsTemplateFilesEditor,
    GsTemplatePreview,
    GsDataTable,
    GsActionsMenu
  },
  data() {
    return {
      appSettingKey: "templates",
      headers: [
        {
          text: this.$t("actions"),
          value: "actions",
          width: 100,
          sortable: false
        },
        { text: this.$t("name"), value: "name" },
        { text: this.$t("description"), value: "description", sortable: false },
        { text: this.$t("type"), value: "type", sortable: false },
        { text: this.$t("locale"), value: "locale" },
        {
          text: this.$t("status"),
          value: "status",
          width: 100,
          sortable: false,
          align: "center"
        },
        { text: this.$t("createdAt"), width: "150px", value: "createdAt" },
        { text: this.$t("updatedAt"), width: "150px", value: "updatedAt" }
      ],
      dialogEditorVisible: false,
      dialogAttachedFilesVisible: false,
      dialogPreviewVisible: false,
      previewTemplateData: null,
      initialized: false,
      actions: ["preview", "edit", "edit_attached_files", "delete"]
    };
  },
  watch: {},
  mounted() {
    this.initialized = false;
    this.$nextTick(() => {
      this.initialized = true;
    });
  },
  methods: {
    async refreshTable() {
      try {
        await this.$refs.table.refreshTable();
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async beforeCallApi(params) {
      // params.url += `&ownedBy=${encodeURIComponent(
      //   this.$store.state.user.ownedBy
      // )}`;
      // if (this.filter.createdBy.value) {
      //   params.url += `&createdBy=${encodeURIComponent(
      //     this.filter.createdBy.value
      //   )}`;
      // }
      params.url += `&r8sFields=createdBy.name,updatedBy.name`;
      return params;
    },
    async afterCallApi(params) {
      for (const item of params.items) {
        item.$calculated = item.$calculated || {};
        item.$calculated.$createdBy = item.createdBy?.name;
        item.$calculated.$updatedBy = item.updatedBy?.name;
      }
      return params;
    },
    async createTemplate() {
      this.dialogEditorVisible = true;
      this.$nextTick(async () => {
        if (!this.$refs.templateEditor) {
          return;
        }
        await this.$refs.templateEditor.create();
      });
    },
    previewTemplate(template) {
      if (
        Object.keys(template).length != 0 &&
        template.constructor === Object
      ) {
        this.previewTemplateData = template;
        this.dialogPreviewVisible = true;
      } else {
        this.dialogPreviewVisible = false;
      }
    },
    async editTemplate(template) {
      this.dialogEditorVisible = true;
      this.$nextTick(async () => {
        if (!this.$refs.templateEditor) {
          return;
        }
        await this.$refs.templateEditor.edit(template);
      });
    },
    async modalSave(templateId) {
      this.dialogEditorVisible = false;
      await this.refreshTable();
    },
    async deleteTemplate(template) {
      const result = confirm(`${this.$t("delete_modal")}\n\n${template.name}`);
      if (!result) {
        return;
      }

      try {
        const url = `${this.$store.state.email_manager_api}/templates/${template.templateId}`;
        await callAPI({
          url,
          method: "DELETE"
        });

        const index = this.templates.findIndex(
          p => p.templateId == template.templateId
        );
        this.templates.splice(index, 1); //deleted
      } catch (error) {
        console.log(error);
      }
    },
    async editAttachedFiles(template) {
      this.dialogAttachedFilesVisible = true;
      this.$nextTick(async () => {
        if (!this.$refs.templateFilesEditor) {
          return;
        }
        await this.$refs.templateFilesEditor.edit(template);
      });
    },
    action_activate(actionId, item) {
      switch (actionId) {
        case "preview":
          this.previewTemplate(item);
          break;
        case "edit":
          this.editTemplate(item);
          break;
        case "edit_attached_files":
          this.editAttachedFiles(item);
          break;
        case "delete":
          this.deleteTemplate(item);
          break;
      }
    }
  }
};
</script>

<style lang="scss">
.col-logo {
  width: 26px;
  height: 26px;
  display: inline-block;
}
.v-data-table-header {
  text-transform: uppercase !important;
}
.mdi-file-document {
  // color: $accent !important;
}
.mdi-file-pdf {
  //   color: $red !important;
}
.mdi-file-code {
  //   color: $mOrange !important;
}
</style>
