<template v-if="previewStuff">
  <v-card flat>
    <v-card-title>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="headline"
            >{{ $t("name") }}: {{ template.name }}</v-list-item-title
          >
          <v-list-item-subtitle class="caption"
            >{{ $t("description") }}:
            {{ template.description }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-subtitle class="body-1"
            >{{ $t("subject") }}: {{ template.subject }}</v-list-item-subtitle
          >
          <v-list-item-subtitle class="body-1"
            >{{ $t("locale") }}: {{ $t(template.locale) }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
    </v-card-title>
    <v-card-text>
      <div id="previewFrameContainer">
        <v-progress-circular
          v-if="previewLoading"
          :size="70"
          :width="6"
          indeterminate
          color="primary"
          class="d-flex mt-8 mx-auto"
          >{{ $t("loading") }}</v-progress-circular
        >
        <iframe
          v-if="!previewLoading"
          id="previewFrame"
          :type="dataType"
          :width="width"
          :height="height"
          :src="previewStuff"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;

export default {
  name: "GsTemplatePreview",
  props: {
    width: {
      type: Number,
      default: 600
    },
    height: {
      type: Number,
      default: 560
    },
    dataType: {
      type: String,
      default: "text/html"
    },
    template: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  watch: {
    template: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.previewLoading = true;
        if (
          Object.keys(newValue).length != 0 &&
          newValue.constructor === Object
        ) {
          this.getAssociatedFiles(newValue.templateId).then(result => {
            this.replaceImages(newValue.html, result.images).then(result => {
              this.previewStuff = `data:${
                this.dataType
              };base64, ${this.encodeBase64(result.html)}`;
              this.previewLoading = false;
            });
          });
        } else {
          this.previewStuff = null;
        }
      }
    }
  },
  data() {
    return {
      previewLoading: true,
      previewStuff: null,
      previewZoom: 1.0
    };
  },
  methods: {
    getAssociatedFiles(templateId) {
      const previewImages = [];
      return new Promise(resolve => {
        callAPI({
          url: `${this.$store.state.email_manager_api}/templates/${templateId}/files`,
          method: "GET",
          cache: true
        })
          .then(({ data }) => {
            if (data.length) {
              const requests = data.map(item => {
                return new Promise(resolve => {
                  callAPI({
                    url: `${this.$store.state.email_manager_api}/files/${item.fileId}`,
                    method: "GET",
                    cache: true
                  }).then(({ data }) => {
                    previewImages.push(data);
                    resolve(true);
                  });
                });
              });
              Promise.allSettled(requests).then(() => {
                resolve({ images: previewImages });
              });
            }
          })
          .catch(error => {
            console.log(error);
          });
      });
    },
    replaceImages(html, images) {
      const tempContainer = document.createElement("div");
      tempContainer.innerHTML = html;
      const elements = [...tempContainer.getElementsByTagName("img")];
      return new Promise(resolve => {
        const requests = elements.map(item => {
          return new Promise(resolve => {
            const fileId = images.find(
              x => x.fileName === item.getAttribute("src")
            );
            if (fileId) {
              callAPI({
                url: `${this.$store.state.email_manager_api}/files/${fileId.fileId}/fileContent`,
                method: "GET",
                resType: "blob",
                cache: true
              }).then(({ data }) => {
                this.blobToBase64(data).then(result => {
                  item.setAttribute("src", result);
                  resolve(true);
                });
              });
            } else {
              // set image placeholder here
              item.setAttribute("style", "background-color: #cbcbcb;");
              resolve(true);
            }
          });
        });
        Promise.allSettled(requests).then(() => {
          resolve({ html: tempContainer.innerHTML });
        });
      });
    },
    encodeBase64(stuff) {
      const data = new Buffer(stuff);
      return data.toString("base64");
    },
    blobToBase64(blob) {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise(resolve => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    }
  }
};
</script>

<style lang="scss">
#previewFrameContainer {
  position: relative;
  width: 100%;
  height: 50vh;
  overflow: scroll;
}
#previewFrame {
  transform: scale(1, 1);
  height: 800px;
  width: 100%;
  pointer-events: none;
  border: 1px solid #cbcbcb;
  padding: 12px;
  border-radius: 15px;
  position: absolute;
  top: 0px;
}
</style>
